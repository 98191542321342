import './styles/Styles.scss'
//import { particles, Run } from "./Scripts/particles";
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

const HomeScreen = React.lazy(() => import('./screens/HomeScreen'))
const Development = React.lazy(() => import('./screens/services/Development'))

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route index element={<HomeScreen />} />
				<Route path='/development' element={<Development />} />
			</Routes>
		</BrowserRouter>
	)
}

export default App
